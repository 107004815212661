import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import moment from 'moment';
import { DndDropEvent } from 'ngx-drag-drop';
import { AppUtils, Constants } from "src/helpers";
import { SelectListItemModel } from 'src/models';
import { UserMediaAppWebPageModel } from 'src/app/media-app/models';
import { UserMediaAppWeatherUpsertModel } from 'src/app/media-app/models/user-media-app-weather-upsert.model';
import { UserMediaAppCanvaUpsertModel, UserMediaAppClockUpsertModel, UserMediaAppGoogleSlideUpsertModel, UserMediaAppImageUpsertModel, UserMediaAppPdfUpsertModel, UserMediaAppPowerPointUpsertModel, UserMediaAppSummaryModel, UserMediaAppVideoUpsertModel, UserMediaAppVimeoUpsertModel, UserMediaAppYouTubeUpsertModel } from 'src/app/shared/models';
import { UserMediaAppUpsertModel } from 'src/app/shared/models/user-media-app/user-media-app-upsert.model';
import { BaseService, UserMediaAppService } from 'src/app/shared/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-media-edit',
    templateUrl: './media-edit.component.html',
    styleUrls: ['./media-edit.component.scss']
})
export class MediaEditComponent implements OnChanges {
    @Input() selectedMedias: Array<UserMediaAppSummaryModel>;
    @Output() userMediaAppUpdated: EventEmitter<{ selectedUserMediaApp: UserMediaAppSummaryModel }> = new EventEmitter<{ selectedUserMediaApp: UserMediaAppSummaryModel }>();
    @Output() deleteMedia = new EventEmitter<number>();

    medias: Array<UserMediaAppSummaryModel>;
    model: UserMediaAppUpsertModel;
    showScheduleFields: boolean;
    activePanel: string;
    isLoading: boolean;
    isModelLoaded: boolean
    mediaStatus: Array<SelectListItemModel>;
    selectedMediaStatus: number;
    selectedDisplayTime: number;
    minDateTime: any;
    scheduleStartTime: any;
    scheduleEndTime: any;
    isScheduled: boolean = false;
    isEndTimeValid: boolean = true;
    isStartTimeEndTimeValid: boolean = true;
    isEndDateValid: boolean = true;
    draggedItemId: number;
    displayTime: number;

    constructor(private baseService: BaseService,
        private userMediaAppService: UserMediaAppService,
        public appUtils: AppUtils,
        private modal: NzModalService
    ) {
    }

    ngOnChanges(): void {
        this.medias = this.selectedMedias;
        this.medias.forEach((x, index) => {
            x.isActive = false;
            x.displayOrder = index + 1;
        });

    }

    onCollapseChange(event: any, item: UserMediaAppSummaryModel): void {
        if (event) {
            item.isActive = true;
            let medias = this.medias.filter(x => x.id != item.id);
            if(medias != undefined){
                medias.forEach(x => x.isActive = false);
            }
            this.getForEdit(item);
        }
        else {
            item.isActive = false;
        }
    }

    scheduleTypeChanged(isDaily: boolean) {
        this.model.isDaily = isDaily;
        this.model.startDate = null;
        this.model.endDate = null;
    }

    getForEdit(item: UserMediaAppSummaryModel): void {
        this.isLoading = true;
        this.isModelLoaded = false;
        item.isActive = true;
        this.displayTime = item.lengthInSeconds;
        this.userMediaAppService.getForEdit(item.id)
            .subscribe({
                next: (response: any) => {
                    this.isScheduled = false;
                    this.isEndTimeValid = true;
                    this.isStartTimeEndTimeValid = true;
                    this.isEndDateValid = true;

                    if (response.type == Constants.mediaAppType.image) {
                        if (!response.image) {
                            response.image = new UserMediaAppImageUpsertModel();
                        }
                    }//video
                    if (response.type == Constants.mediaAppType.video) {
                        if (!response.video) {
                            response.video = new UserMediaAppVideoUpsertModel();
                        }
                    }//pdf
                    if (response.type == Constants.mediaAppType.pdf) {
                        if (!response.pdf) {
                            response.pdf = new UserMediaAppPdfUpsertModel();
                        }
                    }//canva
                    if (response.type == Constants.mediaAppType.canva) {
                        if (!response.canva) {
                            response.canva = new UserMediaAppCanvaUpsertModel();
                        }
                    }//google-slide
                    if (response.type == Constants.mediaAppType.googleSlide) {
                        if (!response.googleSlide) {
                            response.googleSlide = new UserMediaAppGoogleSlideUpsertModel();
                        }
                    }//power-point
                    if (response.type == Constants.mediaAppType.powerPoint) {
                        if (!response.powerPoint) {
                            response.powerPoint = new UserMediaAppPowerPointUpsertModel();
                        }
                    }//vimeo
                    if (response.type == Constants.mediaAppType.vimeo) {
                        if (!response.vimeo) {
                            response.vimeo = new UserMediaAppVimeoUpsertModel();
                        }
                    }//youtube
                    if (response.type == Constants.mediaAppType.youTube) {
                        if (!response.youTube) {
                            response.youTube = new UserMediaAppYouTubeUpsertModel();
                        }
                    }//weather
                    if (response.type == Constants.mediaAppType.weather) {
                        if (!response.weather) {
                            response.weather = new UserMediaAppWeatherUpsertModel();
                        }
                    }//webpage
                    if (response.type == Constants.mediaAppType.webPage) {
                        if (!response.webPage) {
                            response.webPage = new UserMediaAppWebPageModel();
                        }
                    }//clock
                    if (response.type == Constants.mediaAppType.clock) {
                        if (!response.clock) {
                            response.clock = new UserMediaAppClockUpsertModel();
                        }
                    }

                    this.model = new UserMediaAppUpsertModel();
                    Object.assign(this.model, response);


                    if (response.type == Constants.mediaAppType.image) {
                        this.model.image.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.image.isEnabled;
                    }//video
                    if (response.type == Constants.mediaAppType.video) {
                        this.model.video.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.video.isEnabled;
                    }//pdf
                    if (response.type == Constants.mediaAppType.pdf) {
                        this.model.pdf.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.pdf.isEnabled;
                    }//canva
                    if (response.type == Constants.mediaAppType.canva) {
                        this.model.canva.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.canva.isEnabled;
                    }//google-slide
                    if (response.type == Constants.mediaAppType.googleSlide) {
                        this.model.googleSlide.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.googleSlide.isEnabled;
                    }//power-point
                    if (response.type == Constants.mediaAppType.powerPoint) {
                        this.model.powerPoint.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.powerPoint.isEnabled;
                    }//vimeo
                    if (response.type == Constants.mediaAppType.vimeo) {
                        this.model.vimeo.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.vimeo.isEnabled;
                    }//youtube
                    if (response.type == Constants.mediaAppType.youTube) {
                        this.model.youTube.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.youTube.isEnabled;
                    }//weather
                    if (response.type == Constants.mediaAppType.weather) {
                        this.model.weather.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.weather.isEnabled;
                    }//web page
                    if (response.type == Constants.mediaAppType.webPage) {
                        this.model.webPage.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.webPage.isEnabled;
                    }//clock
                    if (response.type == Constants.mediaAppType.clock) {
                        this.model.clock.userMediaAppId = this.model.id;
                        this.model.isEnabled = this.model.clock.isEnabled;
                    }

                    this.model.originalIsEnabled = this.model.isEnabled;

                    if (this.model.startDate && this.model.endDate) {
                        if (moment(this.minDateTime).isAfter(moment(this.model.startDate))) {
                            this.minDateTime = this.model.startDate;
                        }
                        this.model.isScheduled = true;
                        this.model.isDaily = false;
                        this.scheduleStartTime = this.appUtils.getDateTime(this.model.startDate, this.model.startTime);
                        this.scheduleEndTime = this.appUtils.getDateTime(this.model.endDate, this.model.endTime);

                    }
                    else if (this.model.startTime && this.model.endTime) {
                        const current = new Date().toDateString();
                        this.scheduleStartTime = this.appUtils.getDateTime(current, this.model.startTime);
                        this.scheduleEndTime = this.appUtils.getDateTime(current, this.model.endTime);
                        this.model.isScheduled = true;
                        this.model.isDaily = true;
                    } else {
                        this.model.isScheduled = false;
                    }

                    this.isScheduled = this.model.isScheduled;
                    this.checkMediaStatus();
                    this.isModelLoaded = true;
                    this.isLoading = false;

                },
                error: (error) => {
                    this.isLoading = false;
                    this.isModelLoaded = true;
                    this.baseService.processErrorResponse(error);
                }
            })
    }

    update(event: any, item: UserMediaAppSummaryModel): void {
        item.isActive = false;
        event.stopPropagation();
        this.submit();
    }


    confirmUpdateMedia(event: any, item: UserMediaAppSummaryModel): void {
        if (this.model.isEnabled == this.model.originalIsEnabled) {
            this.update(event, item);
            return;
        }
        const modalRef = this.modal.create({
            nzTitle: this.createModalTitle(),
            nzContent: ConfirmDialogComponent,
            nzData: {
                message: `This action will change the status of this media on all the linked playlists. Do you want to continue?`,
                submitButtonText: 'Yes',
            },
            nzWidth: '450px',
            nzClosable: true,
            nzFooter: null,
        });
        modalRef.afterClose.subscribe((response: boolean) => {
            if (response) {
                this.update(event, item);
            }
        });
    }

    createModalTitle(): any {
        return `
      <span>Confirm Action
      <span class="mt-2"><button nz-button nzType="text" class="close-btn" (click)="modalRef.close()">
        <i nz-icon nzType="close"></i>
      </button></span>
      </span>
     
    `;
    }

    close(event: any, item: UserMediaAppSummaryModel): void {
        item.isActive = false;
        item.lengthInSeconds = this.displayTime;
        event.stopPropagation();
    }

    checkMediaStatus() {
        if (!this.model.startTime || !this.model.endTime) {
            this.model.isScheduled = false;
            return;
        }
        this.model.isScheduled = true;
    }

    onSchedule() {
        this.model.startDate = null;
        this.model.endDate = null;
        this.scheduleStartTime = null;
        this.scheduleEndTime = null;
    }

    isStartTimeValid(time: any): void {
        const startTime = moment(time, 'hh:mm A').set({ s: 0 });
        const endTime = moment(this.scheduleEndTime, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    isTimeValid(time: any): void {
        const startTime = moment(this.scheduleStartTime, 'hh:mm A').set({ s: 0 });
        const endTime = moment(time, 'hh:mm A').set({ s: 0 });
        if (
            !moment(endTime).isAfter(moment(startTime)) &&
            startTime.format('a') === endTime.format('a')
        ) {
            this.isEndTimeValid = false;
        } else {
            this.isEndTimeValid = true;
        }
    }

    disableStartDate = (current: Date): boolean => {
        return current && current < new Date(new Date().setHours(0, 0, 0, 0));
    };

    disableEndDate = (current: Date): boolean => {
        if (!this.model.startDate) {
            return current && current < new Date(new Date().setHours(0, 0, 0, 0));
        }
        return current && current < new Date(this.model.startDate);
    };



    getDuration(id: number, displayListId: number) {
        this.isLoading = true;
        this.userMediaAppService.getDuration(id, displayListId)
            .subscribe({
                next: (response: number) => {
                    this.isLoading = false;
                    this.model.lengthInSeconds = response.toString();
                },
                error: (error) => {
                    this.isLoading = false;
                    this.baseService.processErrorResponse(error);
                }
            });
    }

    validateScheduleDetails(): boolean {
        if (!this.model.isScheduled) {
            this.model.startTime = null;
            this.model.endTime = null;
            this.model.startDate = null;
            this.model.endDate = null;
            return true;
        }

        if (this.scheduleStartTime && this.scheduleEndTime) {
            if (!this.isStartTimeEndTimeValid) {
                return false;
            }
            const startTime = moment(this.scheduleStartTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });
            const endTime = moment(this.scheduleEndTime, 'MM/DD/YYYY hh:mm A').set({ s: 0 });

            if (!moment(endTime).isAfter(moment(startTime)) &&
                startTime.format('a') === endTime.format('a')) {
                this.isEndTimeValid = false;
                return false;
            }
            this.model.startTime = this.appUtils.getFormattedTime(startTime.toString(), null);
            this.model.endTime = this.appUtils.getFormattedTime(endTime.toString(), null);

            if (this.model.isDaily) {
                this.model.startDate = null;
                this.model.endDate = null;
                return true;
            }
            this.model.startDate = this.appUtils.getFormattedDate(this.model.startDate, null);
            this.model.endDate = this.appUtils.getFormattedDate(this.model.endDate, null);

            if (moment(this.model.endDate).isBefore(this.model.startDate)) {
                this.isEndDateValid = false;
                return false;
            }
            this.isEndDateValid = true;
            return true;
        } else {
            return false;
        }
    }

    updateMediaStatus(): void {

        switch (this.model.type) {
            case Constants.mediaAppType.image:
                this.model.image.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.video:
                this.model.video.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.pdf:
                this.model.pdf.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.canva:
                this.model.canva.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.googleSlide:
                this.model.googleSlide.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.powerPoint:
                this.model.powerPoint.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.vimeo:
                this.model.vimeo.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.youTube:
                this.model.youTube.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.weather:
                this.model.weather.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.webPage:
                this.model.webPage.isEnabled = this.model.isEnabled;
                break;
            case Constants.mediaAppType.clock:
                this.model.clock.isEnabled = this.model.isEnabled;
                break;
        }
    }

    submit(): void {
        this.isLoading = true;
        if (this.model.groupId == null || this.model.groupId == -1) {
            this.model.groupId = null;
        }

        this.model.isScheduled = this.isScheduled;
        if (!this.validateScheduleDetails()) {
            this.isScheduled = true;
            this.isLoading = false;
            return;
        }

        this.updateMediaStatus();

        this.userMediaAppService.edit(this.model).subscribe({
            next: () => {
                this.isLoading = false;
                let media = this.medias.find(x => x.id == this.model.id);
                media.isScheduled = this.model.isScheduled;
                media.isEnabled = this.model.isEnabled;
                media.status = this.model.isEnabled ? 1 : 2;
                //TODO: close drawer
                let selectedUserMedia = new UserMediaAppSummaryModel();
                selectedUserMedia.id = this.model.id;
                selectedUserMedia.lengthInSeconds = media.lengthInSeconds;
                this.userMediaAppUpdated.emit({ selectedUserMediaApp: selectedUserMedia });
            },
            error: (error: any) => {
                //TODO: close drawer
                this.isLoading = false;
                this.baseService.processErrorResponse(error);
            }
        });
    }

    removeMedia(index: number, item: UserMediaAppSummaryModel): void {
        const deletedMedia = this.medias.splice(index, 1);
        this.deleteMedia.emit(deletedMedia[0].id);

        // const deletedList = this.selectedBroadcasts.splice(index, 1);
        // this.selectedBroadcasts = this.selectedBroadcasts.filter(x => x.id !== deletedList[0].id);
        // this.model.broadcastIds = this.model.broadcastIds.filter(x => x !== deletedList[0].id);
        // this.selectedBroadcastIds = this.selectedBroadcastIds.filter(x => x !== deletedList[0].id);
    }

    onMediaUrlError(item: UserMediaAppSummaryModel): void {

    }

    onDragged(item: any, list: UserMediaAppSummaryModel[]) {
        const index = list.indexOf(item);
        list.splice(index, 1);

        list.forEach((x, index) => {
            x.displayOrder = index + 1;
        });
    }

    onDrop(event: DndDropEvent, list: UserMediaAppSummaryModel[]) {
        let index = event.index;
        list.splice(index, 0, event.data);
    }

}
